import axios from 'axios'
const API_URL = `${process.env.REACT_APP_API_URL}`;
const CBEWSL_SITE = process.env.REACT_APP_SITE

export const getEvents = (callback) => {
    axios.get(`${API_URL}/api/events/get_all_events/${CBEWSL_SITE}`).then((response) => {
        callback(response.data)
    }).catch((error) => {
    
    });
}

export const addEvent = (data,callback) => {
    axios.post(`${API_URL}/api/events/save_activity`, data).then((response) => {
        callback(response.data)
    }).catch((error) => {
    
    });
}

export const deleteEvent = (data,callback) => {
    axios.post(`${API_URL}/api/events/delete_activity`, data).then((response) => {
        callback(response.data)
    }).catch((error) => {
    
    });
}

export const uploadActivityImage = (formData, callback) => {
    axios
      .post(`${API_URL}/storage/activity_schedule`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // handle the response
        console.log(response);
      })
      .catch((error) => {
        // handle errors
        console.log(error);
      });
  };
  