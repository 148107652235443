import { Box, Grid, Typography, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { Fragment, useEffect, useRef, useState } from "react";
import mdrrmc_logo from "../../assets/mdrrmo_seal.png";
import paranas_seal from "../../assets/paranas_seal.png";
import moment from "moment";
import { PDFExport } from "@progress/kendo-react-pdf";
import html2canvas from "html2canvas";
const CBEWSL_SITE_LOCATION = `${process.env.REACT_APP_SITE_LOCATION}`;

const GeneratedBulletin = () => {
  const location = useLocation();
  const currentYear = moment().format("YYYY");
  const siteName = location.state.siteCode.toUpperCase()
  const pdfExportComponent = React.useRef(null);
  const exportContentRef = useRef(null); 
  const [formattedTriggerSource, setFormattedTriggerSource] = useState([
    {
      trigger: "ground_movement",
      tech_infos: [],
    },
    {
      trigger: "landslide_features",
      tech_infos: [],
    },
    {
      trigger: "earthquake",
      tech_infos: [],
    },
    {
      trigger: "rainfall",
      tech_infos: [],
    },
    {
      trigger: "on_demand",
      tech_infos: [],
    },
  ]);

  useEffect(() => {
    if (location.state.triggerSource) {
      const trigger_sources = location.state.triggerSource;
      const ground_movements = [
        "subsurface",
        "landslide features",
        "surficial",
      ];
      const temp = [];
      trigger_sources.forEach((row) => {
        const { source, description, info } = row;
        if (ground_movements.includes(source.toLowerCase())) {
          const existing_ground_movement = temp.find(
            (e) => e.trigger === "ground_movement"
          );
          console.log(existing_ground_movement);
          if (existing_ground_movement) {
            existing_ground_movement.tech_infos.push({
              description,
              info,
            });
          } else {
            temp.push({
              trigger: "ground_movement",
              tech_infos: [{ description, info }],
            });
          }
        } else {
          temp.push({
            trigger: source.toLowerCase(),
            tech_infos: [{ description, info }],
          });
        }
        
      }
     
    );
      setFormattedTriggerSource(temp);
    }
  }, []);

  const PageTemplate = (props) => {
    return (
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    );
  };

  const downloadAsImage = () => {
    if (exportContentRef.current) {
      html2canvas(exportContentRef.current, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = `alert-bulletin-${moment().format("YYYY-MM-DD HH:mm:ss")}.png`;
        link.click();
      }).catch((error) => {
        console.error("Error capturing image:", error);
      });
    }
  };

  return (
    <div>
      <div ref={exportContentRef}>
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 2,
          maxWidth: 800,
          height: "auto",
          border: "2px solid black",
        }}
      >
      <PDFExport
        pageTemplate={PageTemplate}
        keepTogether="typography"
        avoidLinks
        paperSize="A4"
        scale={0.7}
        margin="5mm"
        ref={pdfExportComponent}
        fileName={`alert-bulletin-${moment().format("YYYY-MM-DD HH:mm:ss")}`}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          textAlign="center"
        >
          <Grid
            container
            alignItems="center"
            justifyContent="flex-center"
          >
            <Grid item xs={2} style={{ marginTop: 20, textAlign: "right" }}>
              <img
                src={paranas_seal}
                alt="letter-header"
                style={{
                  objectFit: "contain",
                  width: 100,
                  height: 100,
                }}
              />
            </Grid>
            
            <Grid item xs={8} style={{ textAlign: "center", marginTop: 20 }}>
              <Typography variant="body1" lineHeight={1.2} fontSize={19}>
                <b>Republic of the Philippines</b><br/>
                <b>Province of Samar</b><br/>
                <b>Municipality of Paranas</b><br/>
                <b>Community-based Early Warning System for Landslides</b><br/>
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ marginTop: 20, textAlign: "left"  }}>
              
              <img
                src={mdrrmc_logo}
                alt="letter-header"
                style={{
                  objectFit: "contain",
                  width: 110,
                  height: 110,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            <Typography variant="h5">
              <b>CBEWS-L ALERT LEVEL INFORMATION: {siteName}-{currentYear}</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" style={{ marginBottom: 20 }}>
          <Box
            sx={{
              width: 600,
              height: "auto",
              border: "2px solid black",
              padding: 2,
            }}
          >
            <Typography variant="body1">
              Location: <strong>{location.state.siteLocation}</strong>
            </Typography>
            <Typography variant="body1">
              Date/Time:{" "}
              <strong>
                {moment(location.state.currentAlertTs).format(
                  "MMMM D, YYYY, h:mm A"
                )}
              </strong>
            </Typography>
            <Typography variant="body1">
              Alert Level Released: <strong>{location.state.alertLevel}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ marginLeft: 50, marginRight: 50 }}
          >
            <Typography variant="body1">
              <b>
                <u>AREA SITUATION:</u>
              </b>
            </Typography>
            {formattedTriggerSource.length > 0 ? (
              formattedTriggerSource.map((data, index) => (
                <Fragment key={index}>
                  <Typography variant="subtitle1">
                    <b>{data.trigger.replace("_", " ").toUpperCase()}</b>
                  </Typography>
                
                {data.tech_infos.map((tech_info_data, techIndex) => (
                  <Fragment key={techIndex}>
                    <Typography variant="subtitle1">
                      {tech_info_data.description}
                    </Typography>
                    <ul>
                    <Typography variant="subtitle1" style={{ marginTop: -15 }}>
                      <li>{tech_info_data.info}</li>
                    </Typography>
                    </ul>
                  </Fragment>
                ))}
                </Fragment>
              ))
            ) : (
              <Typography variant="subtitle1">
                No ground movement observed
              </Typography>
            )}
            {/* {location.state.triggerSource.length > 0 ? (
              location.state.triggerSource.map((data, index) => {
                if (data.source.toLowerCase() === "landslide features") {
                  return (
                    <Fragment key={index}>
                      <Typography variant="subtitle1">
                        <b>LANDSLIDE FEATURES</b>
                      </Typography>
                      <Typography variant="subtitle1" style={{ marginTop: -15 }}>
                        <ul><li>{data.info}. {data.description}</li></ul>
                      </Typography>
                    </Fragment>
                  );
                } else if (data.source.toLowerCase() === "rainfall") {
                  return (
                    <Fragment key={index}>
                      <Typography variant="subtitle1">
                        <b>RAINFALL</b>
                      </Typography>
                      <Typography variant="subtitle1" style={{ marginTop: -15 }}>
                        <ul><li>{data.info}. {data.description}</li></ul>
                      </Typography>
                    </Fragment>
                  );
                } else if (data.source.toLowerCase() === "earthquake" ||
                  data.source.toLowerCase() === "surficial" ||
                  data.source.toLowerCase() === "subsurface") {
                  return (
                    <Fragment key={index}>
                      <Typography variant="subtitle1">
                        <b>GROUND MOVEMENT</b>
                      </Typography>
                      <Typography variant="subtitle1" style={{ marginTop: -15 }}>
                        <ul><li>{data.info}. {data.description}</li></ul>
                      </Typography>
                    </Fragment>
                  );
                } else {
                  return null; 
                }
              })
            ) : (
              <Grid>
                <Typography variant="subtitle1">
                  No ground movement observed
                </Typography>
              </Grid>
            )} */}
            <Typography variant="subtitle1" style={{ marginTop: 20 }}>
              <b><u>ELEMENTS AT RISK:</u></b>
            </Typography>
            <Typography variant="subtitle1">
              {location.state.households}
            </Typography>
            <Typography variant="body1" style={{ marginTop: 20 }}>
              <b>
                <u>OTHER RECOMMENDATIONS:</u>
              </b>
            </Typography>
            {location.state.communityResponse !== "" && (
              <Typography variant="subtitle1">
                <b>For the Community:</b> {location.state.communityResponse}
              </Typography>
            )}
            {location.state.lewcResponse !== "" && (
              <Typography variant="subtitle1">
                <b>For the Landslide Early Warning Committee (LEWC):</b>{" "}
                {location.state.lewcResponse}
              </Typography>
            )}
            {location.state.barangayResponse !== "" && (
              <Typography variant="subtitle1">
                <b>For the Barangay:</b> {location.state.barangayResponse}
              </Typography>
            )}
            {location.state.municipalResponse !== "" && (
              <Typography variant="subtitle1">
                <b>For the Municipality:</b> {location.state.municipalResponse}
              </Typography>
            )}
            {location.state.provincialResponse !== "" && (
              <Typography variant="subtitle1">
                <b>For the Province:</b> {location.state.provincialResponse}
              </Typography>
            )}
            <Typography variant="subtitle1" style={{ marginTop: 20, marginBottom: 10 }}>
              <b>NOTE:</b> This bulletin contains the official Alert Level and
              Recommended Response of the MDRRMO Paranas for{" "}
              {location.state.barangay} and will hold true until a new bulletin is
              released.
            </Typography>
          </Grid>
        </Grid>
      </PDFExport>
      </Box>
      </div>
      

      <Grid
        item
        xs={12}
        style={{ justifyContent: "center", textAlign: "center", marginTop: 10, marginBottom: 30 }}
      >
        <Button
          variant="contained"
          onClick={() => {
            if (pdfExportComponent.current) {
              pdfExportComponent.current.save();
            }
          }}
        >
          Download as PDF
        </Button>
        <Button
          variant="contained"
          onClick={downloadAsImage}
          style={{ marginLeft: 10 }}
        >
          Download as Image
        </Button>
      </Grid>
    </div>
  );
};

export default GeneratedBulletin;
