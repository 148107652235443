import React, {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Grid,
  Container,
  Button,
  Typography,
  FormControl,
  Box,
  ListItem,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';

const MomsTable = props => {
  const {instances} = props;

  return (
    <Grid cointainer width={'100%'}>
      <Grid item xs={12}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">Feature Type</TableCell>
              <TableCell align="center">Feature Name</TableCell>
              <TableCell align="center">Location</TableCell>
              <TableCell align="center">Last Observance Timestamp</TableCell>
              <TableCell align="center">Alert Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {instances.hasOwnProperty('data') > 0 &&
              instances.data
                .sort((a, b) => {
                  return (
                    new Date(b.moms[0].observance_ts) -
                    new Date(a.moms[0].observance_ts)
                  );
                })
                .map(
                  row =>
                    row.moms.length > 0 && <Row key={row.date} row={row} />,
                )}
          </TableBody>
        </Table>
        {/* <FabMuiTable
                data={{
                  columns: columns,
                  rows: dummyData,
                }}
                options={options}
              /> */}
      </Grid>
    </Grid>
  );
};

function Row(props) {
  const {row} = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {borderBottom: 'unset'},
          backgroundColor: open ? '#E0E0E0FD' : 'none',
        }}
        onClick={() => setOpen(!open)}>
        <TableCell style={{width: 2}}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.feature.feature_type}
        </TableCell>
        <TableCell align="center">{row.feature_name}</TableCell>
        <TableCell align="center">{row.location}</TableCell>
        <TableCell align="center">
          {moment(row.moms[0].observance_ts).format('LL hh:mm A')}
        </TableCell>
        <TableCell align="center">{row.moms[0].op_trigger}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 5}}>
              <Typography variant="h6" gutterBottom component="div">
                {`${row.feature.feature_type.toUpperCase()}  ${
                  row.feature_name
                }`}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Observance Timestamp</TableCell>
                    <TableCell>Feature Description</TableCell>
                    <TableCell align="center">Report Timestamp</TableCell>
                    <TableCell align="center">Reporter</TableCell>
                    {/* <TableCell>Validator</TableCell> */}
                    <TableCell>Narratives</TableCell>
                    <TableCell align="center">Alert Level</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.moms
                    .sort((a, b) => {
                      return (
                        new Date(b.observance_ts) - new Date(a.observance_ts)
                      );
                    })
                    .map(element => (
                      <TableRow key={element.moms_id}>
                        <TableCell align="center" component="th" scope="row">
                          {moment(element.observance_ts).format('LL hh:mm A')}
                        </TableCell>
                        <TableCell>
                          <ul
                            style={{
                              listStyleType: 'none',
                              paddingLeft: 0,
                            }}>
                            {element.narrative.narrative
                              .split('\n')
                              .map((line, i) => (
                                <li
                                  // sx={{ marginTop: 0, marginBottom: 0 }}
                                  key={i}>
                                  {line}
                                </li>
                              ))}
                          </ul>
                        </TableCell>
                        <TableCell align="center">
                          {moment(element.narrative.timestamp).format(
                            'LL hh:mm A',
                          )}
                        </TableCell>
                        <TableCell align="center">Community</TableCell>
                        {/* <TableCell>{`${element.validator.first_name} ${element.validator.last_name}`}</TableCell> */}
                        <TableCell>
                          <ul
                            style={{
                              listStyleType: 'none',
                              paddingLeft: 0,
                            }}>
                            {element.remarks.split('\n').map((line, i) => (
                              <li sx={{marginTop: 1}} key={i}>
                                {line}
                              </li>
                            ))}
                          </ul>
                        </TableCell>
                        <TableCell align="center">
                          {element.op_trigger}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default MomsTable;
