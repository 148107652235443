import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getFeatures = (site_code, callback) => {
  console.log('ano yung site code?', site_code);
  axios
    .get(
      `${API_URL}/api/manifestations_of_movement/get_moms_features/${site_code}`,
    )
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getInstances = (site_code, callback) => {
  axios
    .get(
      `${API_URL}/api/manifestations_of_movement/get_moms_instances/${site_code}`,
    )
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const insertMomsEntry = (data, callback) => {
  axios
    .post(
      `${API_URL}/api/manifestations_of_movement/write_monitoring_moms_to_db`,
      data,
    )
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getMomsInstances = callback => {
  axios
    .get(`${API_URL}/api/manifestations_of_movement/get_moms_instances/24`)
    .then(response => {
      callback(response);
    });
};

export const getMomsFeatures = callback => {
  axios
    .get(`${API_URL}/api/manifestations_of_movement/get_moms_features/24`)
    .then(response => {
      callback(response);
    });
};

export function getCandidateAlert(callback) {
  const api_link = `${API_URL}/api/monitoring/candidate_alerts`;
  axios
    .get(api_link)
    .then(response => {
      const {data} = response;
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function updateAlertStatus(input, callback) {
  const api_link = `${API_URL}/api/monitoring/update_alert_status`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function releaseAlert(input, callback) {
  const api_link = `${API_URL}/api/monitoring/insert_ewi`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function sendMessage(input, callback) {
  const api_link = `${API_URL}/message/send_ewi`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function getReleasedMessages(release_id, callback) {
  const api_link = `${API_URL}/api/monitoring/get_release_acknowledgement/${release_id}`;
  axios
    .get(api_link)
    .then(response => {
      const {data} = response;
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function getTempMoms(callback) {
  const api_link = `${API_URL}/api/manifestations_of_movement/get_temp_moms`;
  axios
    .get(api_link)
    .then(response => {
      const {data} = response;
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function updateMoms(input, callback) {
  const api_link = `${API_URL}/api/manifestations_of_movement/write_monitoring_moms_to_db`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function getContacts(callback) {
  const api_link = `${API_URL}/message/contacts`;
  axios
    .get(api_link)
    .then(response => {
      const {data} = response;
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function insertOnDemandToDb(on_demand_data, callback) {
  const api_link = `${API_URL}/api/monitoring/save_on_demand_data`;

  axios
    .post(api_link, on_demand_data)
    .then(response => {
      const {data} = response;
      if (callback !== null) {
        callback(data);
      }
    })
    .catch(error => {});
}

export function checkLatestSiteEventIfHasOnDemand(site_id, callback) {
  const api_link = `${API_URL}/api/monitoring/check_if_current_site_event_has_on_demand/24`;
  axios
    .get(api_link)
    .then(response => {
      const {data} = response;
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function getEarthquakeEventsForLast24hrs(json_data, callback) {
  const api_link = `${API_URL}/api/analysis/get_earthquake_events_within_one_day`;
  axios
    .post(api_link, json_data)
    .then(response => {
      const {data} = response;
      if (callback !== null) {
        callback(data);
      }
    })
    .catch(error => {});
}

export const getStaffs = callback => {
  axios
    .get(`${API_URL}/api/misc/get_all_staff_users`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const uploadMomsImage = (formData, callback) => {
  axios
    .post(`${API_URL}/storage/moms`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      // handle the response
      console.log(response);
    })
    .catch((error) => {
      // handle errors
      console.log(error);
    });
};