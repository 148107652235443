import React, {Fragment} from 'react';
import {useParams} from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  Box,
  Modal,
  TextField,
  Checkbox,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import RainfallGraph from '../analysis/RainfallGraph';
import SurficialGraph from '../analysis/SurficialGraph';
import SubsurfaceGraph from '../analysis/SubsurfaceGraph';
import LipataHeader from '../utils/LipataHeader';
import LandslideFeaturesTable from '../analysis/LandslideFeaturesTable';
import EarthquakeChart from '../analysis/EarthquakeChart';

const Analysis = () => {
  const {site_code} = useParams();

  const [state, setState] = React.useState({
    rainfall: false,
    surficial: false,
    subsurface: false,
  });

  const handleChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const {rainfall, surficial, subsurface} = state;
  const error = [rainfall, surficial, subsurface].filter(v => v).length === 0;

  return (
    <Fragment>
      <Grid item xs={12} sx={{padding: 8}}>
        <Grid item xs={12}>
          {/* <Button variant="contained" onClick={handleOpen} sx={{ marginBottom: 4 }}>
            Load Graph per needed timestamp
          </Button> */}
          <Typography variant="h4" sx={{marginBottom: 4}}>
            Analysis charts for{' '}
            {site_code === 'lpa'
              ? 'Barangay Lipata, Paranas, Samar'
              : 'Sitio Campo Uno, Brgy. Tenani, Paranas, Samar'}
          </Typography>
        </Grid>

        {site_code === 'lpa' && (
          <Box>
            <Typography
              variant="h5"
              sx={{
                marginBottom: 4,
                marginTop: 8,
                marginLeft: 1,
                marginBottom: 5,
                backgroundColor: '#e7f5fe',
              }}>
              Rainfall Data
            </Typography>
            <RainfallGraph site_code={site_code} />
          </Box>
        )}

        <Box>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 4,
              marginTop: 8,
              marginLeft: 1,
              marginBottom: 5,
              backgroundColor: '#e7f5fe',
            }}>
            Surficial Data
          </Typography>
          <SurficialGraph site_code={site_code} />
        </Box>

        {site_code === 'lpa' && (
          <Box>
            <Typography
              variant="h5"
              sx={{
                marginBottom: 4,
                marginTop: 8,
                marginLeft: 1,
                backgroundColor: '#e7f5fe',
              }}>
              Subsurface Data
            </Typography>
            <SubsurfaceGraph site_code={site_code} />
          </Box>
        )}

        <Box>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 4,
              marginTop: 8,
              marginLeft: 1,
              backgroundColor: '#e7f5fe',
            }}>
            Earthquake Data
          </Typography>
          <EarthquakeChart />
        </Box>
      </Grid>
    </Fragment>
  );
};

export default Analysis;
