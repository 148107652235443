import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
} from '@mui/material';
import FabMuiTable from '../utils/MuiTable';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  sendMeasurement,
  getStaffs,
  getTableSurficial,
  deleteMeasurement,
} from '../../apis/SurficialMarkers';
import {getSitesData} from './api/opcen';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import PromptModal from './modals/PromptModal';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import SyncIcon from '@mui/icons-material/Sync';

import {useSnackbar} from 'notistack';
import Swal from 'sweetalert2';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 255,
      width: 250,
    },
  },
};

const SurficialMarkers = props => {
  const {site_code} = useParams();
  const [site, setSite] = useState(null);

  useEffect(() => {
    if (site_code)
      getSitesData(results => {
        let tempSite = results.find(site => site.site_code === site_code);
        setSite(tempSite);
      });
  }, [site_code]);

  const [open, setOpen] = useState(false);

  const [staffs, setStaffs] = useState([]);

  const [markersTable, setMarkersTable] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [markers, setMarkers] = useState([]);

  const [openPrompt, setOpenPrompt] = useState(false);
  const [promptTitle, setPromptTitle] = useState('');
  const [notifMessage, setNotifMessage] = useState('');
  const [errorPrompt, setErrorPrompt] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(undefined);

  const [measurement, setMeasurement] = useState({
    date: new Date(),
    time: new Date(),
    reporter: [],
  });
  const [newName, setNewName] = useState(false);

  useEffect(() => {
    if (site) {
      fetchAll();
    }
  }, [site]);

  useEffect(() => {
    fetchAll();
  }, [selectedMonth]);

  const initialize = () => {
    setMeasurement({
      date: new Date(),
      time: new Date(),
      reporter: [],
    });
    setConfirmation(false);
    setIncomplete(false);
    setAtleastOne(true);
  };

  const fetchAll = () => {
    let endDate, startDate;

    if (selectedMonth != undefined) {
      startDate = moment(
        new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1),
      ).format('YYYY-MM-DD HH:mm:00');
      endDate = moment(
        new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0),
      ).format('YYYY-MM-DD HH:mm:00');
    } else {
      endDate = moment(new Date()).format('YYYY-MM-DD HH:mm:00');
      startDate = moment(new Date('2020-10-25')).format('YYYY-MM-DD HH:mm:00');
    }

    let submitData = {
      site_code: site_code,
      startDate: startDate,
      endDate: endDate,
    };

    getTableSurficial(submitData, response => {
      if (response.status) {
        let tempColumns = [
          {name: 'date', label: <b>{'Date'}</b>},
          {name: 'time', label: <b>{'Time'}</b>},
        ];

        response.data.markers.map(col => {
          tempColumns.push({
            name: col,
            label: <b>{`${col.toUpperCase()} (CM)`}</b>,
            
          });
        });

        tempColumns.push({name: 'weather', label: <b>{'Weather'}</b>});
        tempColumns.push({name: 'measurer', label: <b>{'Measurer'}</b>});
        tempColumns.push({
          name: 'timeActual',
          label: 'timeActual',
          options: {display: false, download: false, filter: false},
        });
        tempColumns.push({
          name: 'mo_id',
          label: 'mo_id',
          options: {display: false, download: false, filter: false},
        });
        tempColumns.push({
          name: 'type',
          label: 'type',
          options: {display: false, download: false, filter: false},
        });

        setMarkers(response.data.markers);
        setTableColumns(tempColumns);

        let tempMarkers = [];

        response.data.table_data.map(marker => {
          tempMarkers.push({
            ...marker,
            date: moment(marker.date).format('LL'),
            time: moment(new Date(marker.time)).format('LT'),
            timeActual: marker.time,
          });
        });

        let tempMarkerMeas = tempMarkers.sort((a, b) => b.ts - a.ts);

        setMarkersTable(tempMarkerMeas);
      }
    });

    getStaffs(site_code, response => {
      setStaffs(response.measurers);
    });
  };

  const [incomplete, setIncomplete] = useState(false);
  const [atleastOne, setAtleastOne] = useState(true);

  const checkAtleastOne = () => {
    let valid = false;

    markers.forEach(marker => {
      if (measurement.hasOwnProperty(marker) && measurement[marker] != '')
        valid = true;
    });
    return valid;
  };

  const checkRequired = () => {
    let valid = true;
    if (
      measurement.date != '' &&
      measurement.time != '' &&
      (measurement.hasOwnProperty('weather')
        ? measurement.weather != ''
        : false)
    ) {
      valid = checkAtleastOne();
    } else valid = false;

    return valid;
  };

  const reporterCheck = () => {
    return measurement.hasOwnProperty('reporter')
      ? measurement.reporter.length > 0
        ? true
        : measurement.hasOwnProperty('reporterOther')
        ? measurement.reporterOther != ''
          ? true
          : false
        : false
      : measurement.hasOwnProperty('reporterOther')
      ? measurement.reporterOther != ''
        ? true
        : false
      : false;
  };

  const isAlpha = str => {
    return /^[a-zA-Z ]*$/.test(str);
  };

  const saveMeasurements = () => {
    let tempMarkers = {};
    let reporterStr = measurement.reporter.join(' ');
    if (measurement.reporterOther != undefined) {
      reporterStr += measurement.reporterOther;
    }

    markers.map(marker => {
      if (measurement[marker] > 0)
        tempMarkers[marker.toUpperCase()] = measurement[marker];
    });

    let dateString = `${moment(measurement.date).format('LL')} ${moment(
      new Date(measurement.time),
    ).format('hh:mm A')}`;
    let submitData = {
      date: dateString,
      marker: tempMarkers,
      panahon: measurement.weather,
      reporter: reporterStr.toUpperCase(),
      type: measurement.type,
      site_code: site.site_code,
    };
    console.log(submitData);
    sendMeasurement(submitData, response => {
      if (response.status) {
        setOpen(false);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Kindly wait for atleast 2 minutes for the system to successfully register the data.',
          confirmButtonColor: "#1976D2",
          confirmButtonText: "OK",
        });
        fetchAll();
        initialize();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Error sending ground measurements. Please contact developers',
          confirmButtonColor: "#1976D2",
          confirmButtonText: "OK",
        });
      }
    });
  };

  const submitMeasurements = () => {
    if (isUpdate) {
      deleteMeasurement(measurement.mo_id, response => {
        if (response.status) {
          saveMeasurements();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Error saving ground measurements. Please contact developers',
            confirmButtonColor: "#1976D2",
            confirmButtonText: "OK",
          });
        }
      });
    } else {
      saveMeasurements();
    }
  };

  const handleSubmit = () => {
    let valid = checkRequired() && reporterCheck();

    if (valid) {
      let promptMsg = `Type: ${measurement.type}\n`;
      promptMsg += `Date: ${moment(measurement.date).format('LL')}\n`;
      promptMsg += `Time: ${moment(new Date(measurement.time)).format(
        'hh:mm A',
      )}\n`;
      promptMsg += 'Measurements:\n';

      markers.map(marker => {
        if (measurement.hasOwnProperty(marker) && measurement[marker] != '')
          promptMsg += `${marker.toUpperCase()}: ${measurement[marker]} cm\n`;
      });
      promptMsg += `Weather: ${measurement.weather}\n`;
      promptMsg += `Reporter: ${measurement.reporter.join(' ')} `;
      if (measurement.reporterOther != undefined) {
        promptMsg += measurement.reporterOther;
      }

      setConfirmation(true);
      // setOpen(false);
      setOpenPrompt(true);
      setErrorPrompt(false);
      setPromptTitle('Please confirm ground measurement inputs: ');
      setNotifMessage(promptMsg);
    } else {
      setIncomplete(true);
      setAtleastOne(checkAtleastOne());
    }
  };

  useEffect(() => {
    if (incomplete) setAtleastOne(checkAtleastOne());
  }, [measurement]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsUpdate(false);
    initialize();
  };

  const handleRowClick = (row, i) => {
    let tempMeasurement = {};
    tempMeasurement = {
      date: row[0],
      time: new Date(row[markers.length + 4]),

      weather: row[markers.length + 2].toUpperCase(),
      reporterOther: row[markers.length + 3],
      mo_id: row[markers.length + 5],
      reporter: [],
      type: row[markers.length + 6].toUpperCase(),
    };

    markers.map((marker, i) => {
      tempMeasurement[marker] = row[i + 2];
    });

    setNewName(true);
    setMeasurement(tempMeasurement);
    setOpen(true);
    setIsUpdate(true);
  };

  const options = {
    print: false,
    filter: true,
    // selectableRowsHideCheckboxes: true,
    selectableRows: false,
    filterType: 'dropdown',
    responsive: 'vertical',
    downloadOptions: {
      filename: `surficial_marker_data_${moment().format('YYYY-MM-DD')}`,
    },
    setCellProps: () => ({style: {textAlignLast: 'center'}}),
    // onRowsDelete: rowsDeleted => {
    // const idsToDelete = rowsDeleted.data.map (item => item.dataIndex)
    // handleMuiTableBatchDelete(idsToDelete.sort());
    // },
    onRowClick: handleRowClick,
  };

  return (
    <Grid sx={{px: 10, paddingTop: 2}}>
      <PromptModal
        isOpen={openPrompt}
        error={errorPrompt}
        title={promptTitle}
        setOpenModal={setOpenPrompt}
        notifMessage={notifMessage}
        confirmation={confirmation}
        callback={response => {
          if (response) {
            submitMeasurements();
          }
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{zIndex: 1059}}>
        <DialogTitle id="alert-dialog-title">
          {isUpdate ? 'Update ' : 'Enter new '}surficial marker measurements
        </DialogTitle>
        <DialogContent>
          <FormControl
            error={incomplete && measurement.type == undefined ? true : false}>
            <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
            <RadioGroup
              error={incomplete && measurement.type == undefined ? true : false}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={measurement.type}
              onChange={e => {
                setMeasurement({
                  ...measurement,
                  type: e.target.value,
                });
              }}>
              <FormControlLabel
                sx={{
                  color:
                    incomplete && measurement.type == undefined
                      ? '#d32f2f'
                      : 'default',
                }}
                value="ROUTINE"
                control={
                  <Radio
                    sx={{
                      color:
                        incomplete && measurement.type == undefined
                          ? '#d32f2f'
                          : 'default',
                    }}
                  />
                }
                label="Routine"
              />
              <FormControlLabel
                sx={{
                  color:
                    incomplete && measurement.type == undefined
                      ? '#d32f2f'
                      : 'default',
                }}
                value="EVENT"
                control={
                  <Radio
                    sx={{
                      color:
                        incomplete && measurement.type == undefined
                          ? '#d32f2f'
                          : 'default',
                    }}
                  />
                }
                label="Event"
              />
            </RadioGroup>
            <FormHelperText>Required</FormHelperText>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box flexDirection={'row'} style={{paddingTop: 20}}>
              <DatePicker
                label="Date"
                value={measurement.date}
                onChange={e => {
                  setMeasurement({
                    ...measurement,
                    date: moment(new Date(e)).format('YYYY-MM-DD'),
                  });
                }}
                renderInput={params => (
                  <TextField
                    style={{width: '49%', marginRight: '2%'}}
                    {...params}
                  />
                )}
              />
              <MobileTimePicker
                label="Time of measurement"
                value={measurement.time}
                onChange={e => {
                  setMeasurement({
                    ...measurement,
                    time: e,
                  });
                }}
                renderInput={params => (
                  <TextField
                    style={{width: '49%'}}
                    {...params}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <AccessTimeIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
          <Typography
            style={{paddingTop: 15, color: atleastOne ? 'black' : '#d32f2f'}}>
            Marker measurements:{' '}
            <Typography
              style={{
                fontStyle: 'italic',
                color: atleastOne ? 'gray' : '#d32f2f',
                margin: 0,
                fontSize: '12px',
              }}>
              please input atleast one marker measurement
            </Typography>
          </Typography>
          <Box
            container
            flexDirection={'row'}
            paddingTop={1}
            paddingBottom={2}
            align="center"
            justifyContent={'space-around'}>
            {markers.map(marker => (
              <TextField
                autoFocus
                label={marker.toUpperCase()}
                error={incomplete && !atleastOne}
                variant="outlined"
                defaultValue={measurement[marker]}
                style={{width: '23%', margin: '1%'}}
                onChange={e => {
                  let temp = {...measurement};
                  temp[marker] = e.target.value;
                  setMeasurement(temp);
                }}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
              />
            ))}
          </Box>

          <FormControl
            fullWidth
            style={{width: '100%', paddingBottom: 15}}
            error={
              incomplete &&
              (measurement.weather == '' || measurement.weather == undefined)
                ? true
                : false
            }>
            <InputLabel id="demo-simple-select-label">Weather</InputLabel>
            <Select
              error={
                incomplete &&
                (measurement.weather == '' || measurement.weather == undefined)
                  ? true
                  : false
              }
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Weather"
              value={measurement.weather}
              onChange={e => {
                setMeasurement({
                  ...measurement,
                  weather: e.target.value,
                });
              }}>
              <MenuItem value={'MAARAW'}>Maaraw</MenuItem>
              <MenuItem value={'MAULAP'}>Maulap</MenuItem>
              <MenuItem value={'MAULAN'}>Maulan</MenuItem>
              <MenuItem value={'MAULIMLIM'}>Makulimlim</MenuItem>
              <MenuItem value={'MAAMBON'}>Maambon</MenuItem>
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>

          <FormControl
            fullWidth
            style={{width: '100%', paddingBottom: 15}}
            error={
              incomplete &&
              (measurement.reporter == '' ||
                measurement.reporter == undefined) &&
              (measurement.reporterOther == '' ||
                measurement.reporterOther == undefined)
                ? true
                : false
            }>
            <InputLabel id="demo-simple-select-label">Measurer</InputLabel>
            <Select
              error={
                incomplete &&
                (measurement.reporter.length <= 0 ||
                  measurement.reporter == undefined) &&
                (measurement.reporterOther == '' ||
                  measurement.reporterOther == undefined)
                  ? true
                  : false
              }
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Measurer"
              multiple
              value={measurement.reporter}
              onChange={e => {
                const {
                  target: {value},
                } = e;

                setMeasurement({
                  ...measurement,
                  reporter:
                    typeof value === 'string' ? value.split(', ') : value,
                });
                setDropdownOpen(false);
              }}
              MenuProps={MenuProps}
              onOpen={() => setDropdownOpen(true)}
              onClose={() => setDropdownOpen(false)}
              open={dropdownOpen}>
              {staffs.map(staff => (
                <MenuItem key={staff.user_id} value={staff.name}>
                  {staff.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={newName}
                onChange={e => {
                  setNewName(e.target.checked);
                }}
              />
            }
            label="Add New Name"
            style={{width: '100%'}}
          />
          {newName && (
            <TextField
              id="filled-helperText"
              label="Measurer not on the list"
              placeholder="Ex: Juan Dela Cruz"
              error={isAlpha(measurement.reporterOther) ? false : true}
              helperText={
                isAlpha(measurement.reporterOther)
                  ? ''
                  : 'No numbers and special characters. Example: .,-+=:;%#@&()'
              }
              iant="outlined"
              style={{width: '100%'}}
              value={measurement.reporterOther}
              onChange={e => {
                setMeasurement({
                  ...measurement,
                  reporterOther: e.target.value,
                });
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
          <Button
            variant="contained"
            onClick={e => {
              handleSubmit();
            }}>
            Save Measurements
          </Button>
        </DialogActions>
      </Dialog>

      {(site_code === 'lpa' || site_code === 'scu') && (
        <Grid container spacing={4} sx={{padding: '2%'}}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center" fontSize={40}>
              {site_code === 'lpa'
                ? `Brgy. Lipata`
                : `Sitio Campo Uno, Brgy. Tenani`}{' '}
              Surficial Measurements
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={4} sx={{mt: 2, mb: 6, padding: '2%'}}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h4">Surficial Markers</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={fetchAll}
                style={{
                  float: 'right',
                }}
                endIcon={<SyncIcon />}>
                Update Table
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} paddingBottom={2}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Month and Year'}
                  views={['month', 'year']}
                  value={selectedMonth ? selectedMonth : null}
                  maxDate={new Date(new Date().getFullYear(), 11, 31)}
                  minDate={new Date('2017-08-01')}
                  onChange={e => {
                    console.log('eeee', e);
                    setSelectedMonth(new Date(e));
                  }}
                  renderInput={params => (
                    <TextField style={{width: '35%'}} {...params} />
                  )}
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                onClick={() => setSelectedMonth(undefined)}
                style={{
                  marginTop: 10,
                  marginLeft: 15,
                }}>
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={handleClickOpen}
                style={{
                  float: 'right',
                  marginTop: 10,
                }}>
                Add surficial marker measurement
              </Button>
            </Grid>
          </Grid>

          {markersTable &&
            markersTable.length > 0 &&
            selectedMonth == undefined && (
              <Grid container paddingBottom={2}>
                <Typography fontSize={20}>
                  Displacement from the last 2 measurements:{' '}
                  {markers.map((marker, index) => {
                    if (
                      markersTable[0][marker] != null &&
                      markersTable[1][marker] != null
                    ) {
                      let displacement = Math.abs(
                        (
                          markersTable[0][marker] - markersTable[1][marker]
                        ).toFixed(2),
                      );
                      return (
                        <>
                          <span style={{color: displacement > 1 && 'red'}}>
                            {marker.toUpperCase()}: {displacement} cm
                            {index + 1 < markers.length ? `,` : ``}{' '}
                          </span>
                        </>
                      );
                    }
                  })}
                </Typography>
              </Grid>
            )}
          <Grid item xs={12}>
            <FabMuiTable
              data={{
                columns: tableColumns,
                rows: markersTable,
              }}
              options={options}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SurficialMarkers;
